require("@fontsource/montserrat/latin-500.css")
require("@fontsource/montserrat/latin-300.css")
require("@fontsource/montserrat/latin-700.css")
require("@fontsource/montserrat/latin-400.css")
require("@fontsource/montserrat/latin-600.css")

exports.onServiceWorkerUpdateReady = () => {
  if (typeof window !== "undefined") {
    window.location.reload()
  }
}

// && !/blog\/([^?]+)$/.test(pathname)

exports.shouldUpdateScroll = ({ routerProps: { location: { hash, pathname } } }) => {
  if (typeof window !== "undefined") {
    if (hash && document.getElementById("beginning")) {
      document.querySelector("#gatsby-focus-wrapper").style.scrollBehavior = "smooth"
      const topOffset = document.getElementById(
        hash.replace("#", ""),
      )?.offsetTop
      document.querySelector("#gatsby-focus-wrapper").scrollTop = topOffset + 80
    } else {
      document.querySelector("#gatsby-focus-wrapper").style.scrollBehavior = "unset"
      document.querySelector("#gatsby-focus-wrapper").scrollTop = 0

      if (document.getElementById("beginning")) {
        document.querySelector("#gatsby-focus-wrapper").style.scrollBehavior = "smooth"
      }
    }
  }

  return false
}
